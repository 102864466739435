<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="create_pledge">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
      >
        <el-form-item label="用户钱包地址" prop="wallet">
          <el-input v-model="ruleForm.wallet"></el-input>
        </el-form-item>
        <el-form-item label="质押CPLE数量" prop="count">
          <el-input v-model="ruleForm.count"></el-input>
        </el-form-item>
        <el-form-item label="Txid" prop="txid">
          <el-input v-model="ruleForm.txid"></el-input>
        </el-form-item>

        <div class="btn">
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            class="btn_submit"
            >立即添加</el-button
          >
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";

export default {
  components: { Breadcrumb },

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "用户质押管理",
        },
        {
          name: "手动添加质押",
        },
      ], // 面包屑数据

      ruleForm: {
        wallet: "",
        count: "",
        txid: "",
      },
      rules: {
        wallet: [
          { required: true, message: "请输入用户钱包地址", trigger: "blur" },
        ],
        count: [
          { required: true, message: "请输入质押CPLE数量", trigger: "blur" },
        ],
        txid: [
          { required: true, message: "请输入质押CPLE数量", trigger: "blur" },
        ],
      },
    };
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          showLoading();
          const opt = {
            url: reqUrl.worldTravelManualWorldTravelPledge,
            method: "POST",
            params: JSON.stringify({
              cpleAddress: this.ruleForm.wallet,
              cple: this.ruleForm.count,
              txid: this.ruleForm.txid,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              } else if (res.data.code == 0) {
                this.$message.success("添加成功");
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
            },
          };
          request(opt);
        } else {
          this.$message.error("请检查信息是否有误");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.create_pledge {
  width: 90%;
  height: 300px;
  background: #fff;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .el-input {
    width: 90%;
  }

  .btn {
    display: flex;
    justify-content: center;

    .el-button {
      width: 200px;
      border-radius: 20px;
    }
  }
}
</style>
